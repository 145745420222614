import * as React from 'react';
//import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Social from '../components/social';

import Layout from '../components/layout';
import { useQuery } from '../hooks/useQuery';

export default function Page({ pageContext }) {
  const data = useQuery();
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
    socialTitle: page.frontmatter.featuredAlt,
    imgLocation: `${data.site.siteMetadata.siteUrl}`,
    socialImg: page.frontmatter.featuredSocial?.publicURL,
    socialImgWidth:
      page.frontmatter.featuredSocial?.childImageSharp.original.width,
    socialImgHeight:
      page.frontmatter.featuredSocial?.childImageSharp.original.height,
    socialImgType: page.frontmatter.featuredSocial?.internal.mediaType,
  };
  const url = page.frontmatter.slug
    ? `${data.site.siteMetadata.siteUrl}/${page.frontmatter.slug}`
    : `${data.site.siteMetadata.siteUrl}`;
  console.log(meta);
  console.log('data ,', `${data.site.siteMetadata.siteUrl}/${page.frontmatter.slug}`);

  return (
    <Layout meta={meta} data={data}>
      {/* <pre>{JSON.stringify(page)}</pre> */}
      <div
        className={`${meta.title.toLowerCase().replace(' ', '')} title-bkgd`}
      >
        <h1 className="title is-1 m-2">{page.frontmatter.title}</h1>
        {/* <h1 className="title is-1 m-2">Research Guides</h1> */}

      </div>
      {/* <em>{page.date}</em> - {page.topic}*/}
      <div
        className={`${meta.title
          .toLowerCase()
          .replace(' ', '')} content container py-5`}
      >
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
      <Social url={url} />
    </Layout>
  );
}
